.rel {
  top: 0;
  left: 0;
  position: relative;
}

.abs {
  position: absolute;
  display: block;
  &.fullSz {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.hidden {
  visibility: hidden;
  display: none;
  opacity: 0;
}

.clickKill {
  cursor: none;
}

.mobile .clickKill {
  display: block;
  cursor: pointer;
}

.tl {
  top: 0;
  left: 0;
  position: absolute;
}
.T {
  top: 0;
  left: 50%;
  position: absolute;
}
.tr {
  top: 0;
  right: 0;
  position: absolute;
}
.L {
  top: 50%;
  left: 0;
  position: absolute;
}
.c {
  top: 50%;
  left: 50%;
  position: absolute;
}
.hc {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
}
.tc {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.r {
  top: 50%;
  right: 0;
  position: absolute;
}
.bl {
  bottom: 0;
  left: 0;
  position: absolute;
}
.b {
  bottom: 0;
  left: 50%;
  position: absolute;
}
.br {
  bottom: 0;
  right: 0;
  position: absolute;
}
.full {
  width: 100%;
  height: 100%;
  display: block;
}
.fullW {
  width: 100%;
}
.fullH {
  height: 100%;
}
.invis {
  visibility: hidden;
  opacity: 0;
}
.hList {
  list-style: none;
  white-space: nowrap;
}
.hList li {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
}

.noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@keyframes brightin {
  from {
    filter: brightness(800%) blur(30px);
  }
  to {
    filter: brightness(100%) blur(0px);
    filter: none;
  }
}
.multiply {
  mix-blend-mode: multiply;
}
.overlay {
  mix-blend-mode: overlay;
}
@mixin scaled-font($fontSize, $maxFontSize, $width, $maxWidth) {
  font-size: calc(
    #{$fontSize}px + (#{$maxFontSize} - #{$fontSize}) * (100vw - #{$width}px) / (#{$maxWidth} - #{$width})
  );
  @media screen and (max-width: #{$width}px) {
    font-size: #{$fontSize}px;
  }
  @media screen and (min-width: #{$maxWidth}px) {
    font-size: #{$maxFontSize}px;
  }
}
