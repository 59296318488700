// @import url('https://fonts.googleapis.com/css?family=IBM+Plex+Serif:600,700&display=swap');
@import url('https://use.typekit.net/wjl0zeh.css');

@import 'helpers';

// vars
:root {
  --close-time: 0.35s;
  --close-top: 0px;
  --close-deg: 90deg;
}
$cease1: cubic-bezier(1, 0.21, 0.02, 1.38);
$cease2: cubic-bezier(0.080, 0.910, 0.640, 1.000);
$transition1: transform 1200ms cubic-bezier(0.080, 0.910, 0.640, 1.000);

.off {
  visibility: hidden;
  display: none;
}
body {
  margin: 0px;
  min-height: 100vh;
  // font-size: 5.8vw;
  font-family: acumin-pro, sans-serif;
  font-size: 100%;
  line-height: 1.5em;
  animation-timing-function: ease;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
  animation: brightin 0.6s;
  background: #f3f3f3;
  color: #414141;
  transition: background 3s ease;
}
a:not(.closeBtn) {
  position: relative;
  cursor: pointer;
  overflow: visible;
  white-space: nowrap;
  &:before {
    content: ' ';
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 105%;
    height: 20%;
    background: #de0;
    mix-blend-mode: overlay;
    transition: all 0.25s $cease1;
  }
  &,
  &:hover,
  &:visited {
    color: #414141;
    text-decoration: none;
  }
  @media screen and (hover: hover) {
    &:hover {
      color: #fff;
      &:before {
        height: 100%;
        background: #d00;
      }
    }
  }
  // &:hover {
  // text-decoration: none;
  // }
  // @​media (hover: none) {

  // }
}
canvas {
  background: transparent;
}
#logo {
  z-index: 500;
  width: 21vw;
  height: auto;
  max-width: 178px;
  margin: 0px auto;
  text-align: center;
  left: 0px;
  top: 43%;
  transform: translateY(-50%);
  @media screen and (max-width: 600px) {
    width: 40vw;
  }
}

#main {
  opacity: 0.7;
  height: 100vh;
}
#container {
  display: flex;
  min-width: 60vw;
  top: 47.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: top 2s ease;

  @media screen and (max-width: 600px) {
    // @media screen and (max-width: 600px) and (orientation: portrait) {
    flex-flow: row wrap;
    min-width: 75vw;
    .logo {
      order: 2;
      margin: 10px auto;
      #logo {
        left: 50%;
        top: 0px;
        transform: translateX(-50%);
      }
    }
    #content {
      flex: 1 100%;
      width: 100%;
      margin-left: 0px;
    }
  }
}

p {
  // transform-origin: 50% 0%;
  position: relative;
  font-weight: 500;
  font-size: 2.15vw;
  line-height: 1.3em;
  margin: 0px;
  padding: 0px 0px 1.3vw;
  strong {
    font-weight: 600;
  }
  @media screen and (max-width: 600px) {
    font-size: 3.75vw;
  }
  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
}
#content {
  width: calc(68vw - 178px);
  margin-left: 2vw;
  overflow: hidden;
  // transition: width 2.4s ease-out;
  // &.workSamples {
  //   width: calc(28vw - 178px);
  // }
}
#intro,
#info {
  transform: translateX(0%);
  transition: transform var(--close-time) $cease1;
  .workSamples & {
    transform: translateX(-100%);
  }
}
#intro {
  position: relative;
  .workSamples & {
    position: absolute;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 20px;
  }
}
#workSamples {
  // position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  transform: translateX(-100%);
  transition: all var(--close-time) $cease1;

  .workSamples & {
    transform: translateX(0%);
    display: block;
    position: relative;
  }

  p {
    a:not(.current):not(:hover) {
      &:before {
        // background: none;
      }
    }
  }

  .urls {
    padding-left: 35px;

    @media screen and (max-width: 600px) {
      padding: 35px;
    }
  }
  aside {
    max-width: 17vw;
    opacity: 0.7;
    user-select: none;

    top: 50%;
    left: 36vw;
    transform: translateY(-50%);
    display: inline-block;
    background: #fff;
    color: #464646;
    padding: 14px 14px 8px;
    width: auto;
    box-shadow: 0 0 4.5vw #fff;
    transition: top 3s ease-out;
    h4,
    p {
      font-size: 100%;
      margin: 0px;
      line-height: 1.2em;
      font-style: italic;
    }

    display: none;
    .workSamples & {
      display: inline-block;
    }
    .touch-events & {
      display: none;
      visibility: hidden;
    }
    @media screen and (max-width: 600px) {
      display: none;
      visibility: hidden;
    }
  }
}
.close {
  left: -7px;
  top: -11px;
}
.closeBtn {
  width: 40px;
  height: 40px;
  position: absolute;
  display: inline-block;
  margin: 0px;
  transform: scale(0.55);

  &:before,
  &:after {
    content: ' ';
    position: absolute;
    display: block;
    width: 45px;
    height: 2px;
    background: #555;
    transform-origin: left center;
    transition: transform 0.15s ease-out, background 0.2s;
  }

  &:before {
    top: 50%;
    left: 50%;
    // transform: rotate(45deg) translate(-50%, -50%) scaleX(0);
    transform: rotate(45deg) translate(-50%, -50%) scaleX(1);
    transition-delay: 0s;
  }
  &:after {
    bottom: 50%;
    left: 50%;
    // transform: rotate(-45deg) translate(-50%, 50%) scaleX(0);
    transform: rotate(-45deg) translate(-50%, 50%) scaleX(1);
    transition-delay: 0.05s;
  }

  @media screen and (hover: hover) {
    &:hover {
      &:before,
      &:after {
        background-color: #d00;
      }
      &:after {
        border-right-color: #d00;
      }
    }
  }
}
// @import 'gradients';
